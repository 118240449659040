<script lang="jsx">
// 动态卡片组件
export default {
    data() {
        return {
            options: [],
            rowLayout: { type: 'flex', justify: 'space-between' },
            dataList: []
        }
    },
    computed: {
    },
    props: {
        propOptions: {
            type: Array,
            default: () => []
        },
        mode: {
            type: String,
            default: () => 'default',
            validator: function (value) {
                // 这个值必须匹配下列字符串中的一个
                return ['default', 'multiple', 'tags', 'combobox'].includes(value)
            }
        }
    },
    created() {
        this.options = this.propOptions
    },
    methods: {
        selectOnChange(value, option) {
            const idx = this.options.findIndex(item => item.value === value)
            console.log({ 'this.options': this.options, idx, value, option, 'this.mode': this.mode })
            switch (this.mode) {
                case 'default':
                    this.options[idx].disabled = true
                    break;
                case 'multiple':
                    this.dataList.push({ ...this.options[idx] })
                    break;
                default:
                    break;
            }

        },
        deleteCard(item, index) {
            switch (this.mode) {
                case 'default':
                    item.disabled = false
                    break;
                case 'multiple':
                    this.dataList.splice(index, 1)
                    break;
                default:
                    break;
            }
            item.onDestroyed?.()
        }
    },
    render(h) {
        return (
            <div class='dynamic-card'>
                {(this.mode === 'default' ? this.options.filter(({ disabled }) => disabled) : this.dataList).map((item, index) => {
                    item.delBtn = <div class='card-remove'>
                        <a-button vOn:click={() => this.deleteCard(item, index)} shape="circle" size="small" icon="close" />
                    </div>
                    return (
                        <a-row class='dynamic-card-item' key={this.mode === 'default' ? item.value : index} {...{ attrs: this.rowLayout }}>
                            {item.dataComponent(item, h)}
                        </a-row>
                    )
                })}
                <a-select class="card-select" value='请选择新增项' vOn:change={this.selectOnChange} options={this.options} />
            </div>
        )
    }
}
</script>

<style lang="less" scoped>
.dynamic-card {
    position: relative;

    /deep/ .dynamic-card-item {
        margin-bottom: 30px;

        &>div>.ant-row {
            display: flex;
        }
    }

    .card-remove {
        display: flex;
        align-items: center;
        width: 58px;
        justify-content: end;
    }

    .card-select {
        width: inherit;
    }
}
</style>